import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { AuthServiceWrapper } from './shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import {
  getCurrentOrganisation,
  getLastSelectedOrganisation,
  getRegisterOrganisationPending,
  getUserOrganisations,
} from './shared/state/organisations';
import { IState } from './shared/state';
import { CheckLogin } from './shared/state/auth/auth.actions';
import moment from 'moment';

import { take } from 'rxjs/operators';
import { MediaMatcher } from '@angular/cdk/layout';
import { DashboardService } from './shared/services/dashboard.service';
import { ICurrentOrganisation } from './shared/models/organisation.model';
import { IOrganisationListItem, IUser } from 'cde-fe-organization-registration-dialog';
import { getLocaleByAlpha2 } from 'country-locale-map';
import { screens } from '@app/shared/utils/screen-query-wrapper';
import { ConvertPipe, ConvertPipeService } from 'cde-fe-convert-pipe';
import { GetCurrentOrganisation, SetCurrentOrganisation } from './shared/state/organisations/organisations.actions';
import { MachineImagesUrl } from './shared/state/machine-overview/machines.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { IMaintenanceText, MaintenanceToast } from './shared/models/dashboard.model';
import { LocalStorageService } from './shared/services/local-storage.service';
import { getPendingOrgSwitch, userSelector } from './shared/state/auth';
import { UpdateUserAppMetadata } from './shared/state/user/user.actions';
import { RoutingStateService } from './shared/services/routing-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ConvertPipe],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('toastsWrapper') toastsWrapper: ElementRef;

  inlineToastSubject = new BehaviorSubject<{
    status: boolean;
    timeToLeave: number;
  } | null>({
    status: true,
    timeToLeave: 0,
  });
  inlineToastSubject$ = this.inlineToastSubject.asObservable();

  isExpanded = false;
  orgRegistered: boolean;

  scheduledMaintenanceWork = false;
  maintenanceToasts: MaintenanceToast[] = [];

  public pendingOrgSwitch$ = this.store.select(getPendingOrgSwitch);
  public pendingOrgRegistration$ = this.store.select(getRegisterOrganisationPending);
  public toastsWrapperHeight = 0;
  public validatedOrg: boolean = true;
  public hidevalidatedOrgAlertBanner: boolean;

  private smQuery: MediaQueryList;
  private currentOrganisation$ = this.store.select(getCurrentOrganisation);
  private orgChange$ = this.store.select(getLastSelectedOrganisation);
  private subscription = new Subscription();
  private orgChangeWatcher: Subscription;
  private userWatcher: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthServiceWrapper,
    private convertPipeService: ConvertPipeService,
    private dashboardService: DashboardService,
    private store: Store<IState>,
    private translator: TranslateService,
    private localStorageService: LocalStorageService,
    private cdr: ChangeDetectorRef,
    public routingState: RoutingStateService,
    media: MediaMatcher
  ) {
    this.smQuery = media.matchMedia(`(max-width: ${(screens as any).sm.max})`);
  }

  public ngAfterViewInit(): void {
    this.store.dispatch(new CheckLogin());
    this.observeElementHeight();
  }

  public ngOnInit(): void {
    // sets default translation based on user profile
    this.auth.userProfileSubject$.pipe(take(1)).subscribe((user: IUser) => {
      let lang = user.user_metadata.language;
      lang = lang ? lang : 'de';
      this.translator.use(lang);
      this.convertPipeService.setLanguage(lang);
    });

    this.subscription.add(
      this.currentOrganisation$.subscribe((currentOrg: ICurrentOrganisation | null) => {
        if (currentOrg) {
          const locale = getLocaleByAlpha2(currentOrg.country.toUpperCase());
          moment.locale(locale);

          this.validatedOrg = currentOrg.validated;
          this.convertPipeService.setUnitSystem(currentOrg.unitSystem);
          this.convertPipeService.setCountry(currentOrg.country);
          this.orgRegistered = currentOrg.registered;
          this.store.dispatch(new MachineImagesUrl());
        } else {
          this.getCurrentOrganisation();
        }
      })
    );

    this.subscription.add(
      combineLatest([this.currentOrganisation$, this.store.select(getUserOrganisations)]).subscribe(
        ([currentOrganisation, organisations]) => {
          // get auth0 org ID from the URL query parameters
          const queryParams = this.activatedRoute.snapshot.queryParams;
          if (queryParams?.orgId == null) {
            return;
          }

          // check if the user has the given org in his list of organisations
          const orgId = queryParams.orgId;
          const hasOrganisation = organisations.some((org) => org.auth0Id === orgId);
          if (!hasOrganisation) {
            return;
          }

          // reset the current organisation if the given org is different from the current organisation
          if (currentOrganisation != null && currentOrganisation?.auth0Id !== orgId) {
            this.store.dispatch(new SetCurrentOrganisation({ auth0Id: orgId }));
          }
        }
      )
    );

    this.subscription.add(
      this.localStorageService.observeKey('maintenanceTexts').subscribe((maintenanceTexts) => {
        this.setMaintenanceToasts(maintenanceTexts);
      })
    );

    this.showMigrationLandingPageOnFirstLogin();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.orgChangeWatcher?.unsubscribe();
    this.userWatcher?.unsubscribe();
  }

  public getActionFromNavbar(event: boolean): void {
    this.isExpanded = event;
    this.dashboardService.checkNavbarStatus(this.isExpanded);
  }

  public handleClickedEvent($event: boolean) {
    if (this.smQuery.matches && this.isExpanded) {
      this.isExpanded = !this.isExpanded;
      this.dashboardService.checkNavbarStatus(this.isExpanded);
    }
  }

  getCurrentOrganisation() {
    // if current org is reset to null the subscription might be built up again
    // and thus the old one needs to be unsubscribed first
    this.orgChangeWatcher?.unsubscribe();
    this.orgChangeWatcher = this.orgChange$.subscribe((data: Partial<IOrganisationListItem | null>) => {
      if (data && data.auth0Id) {
        this.store.dispatch(new GetCurrentOrganisation(data.auth0Id));
      }
    });
  }

  closeAlertWarning(index: number) {
    const maintenanceTexts: any[] = this.localStorageService.getItem('maintenanceTexts');
    this.maintenanceToasts = [];
    if (maintenanceTexts.length > 0) {
      if (maintenanceTexts.length === 1) {
        this.localStorageService.removeItem('maintenanceTexts');
      } else {
        maintenanceTexts.splice(index, 1);
        this.localStorageService.setItem('maintenanceTexts', maintenanceTexts);
      }
    }
    this.cdr.detectChanges();
  }

  closeUnverifiedOrgAlertBanner() {
    this.hidevalidatedOrgAlertBanner = true;
    this.observeElementHeight();
    this.cdr.detectChanges();
  }

  setMaintenanceTextsInLocalStorage(maintenanceTexts: IMaintenanceText[]) {
    maintenanceTexts.forEach((maintenanceText, index) => localStorage.setItem(`maintenanceText${index}`, JSON.stringify(maintenanceText)));
  }

  private setMaintenanceToasts(maintenanceTexts: IMaintenanceText[]) {
    maintenanceTexts?.forEach((maintenanceText) => {
      this.maintenanceToasts.push({
        ...maintenanceText,
        ...this.getMaintenanceToastType(maintenanceText.viewType),
      });
    });
  }

  private getMaintenanceToastType(viewType: string): { type: 'error' | 'warning' | 'info' | 'success' } {
    const toastTypes: Record<string, { type: 'error' | 'warning' | 'info' | 'success' }> = {
      /* eslint-disable @typescript-eslint/naming-convention */
      'cc3-teaser-alert-warning': { type: 'warning' },
      'cc3-teaser-alert-info': { type: 'info' },
      'cc3-teaser-alert-error': { type: 'error' },
      /* eslint-enable @typescript-eslint/naming-convention */
    };
    return toastTypes[viewType];
  }

  private observeElementHeight(): void {
    if (this.toastsWrapper) {
      const element = this.toastsWrapper.nativeElement;

      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          this.toastsWrapperHeight = entry.contentRect.height > 0 ? entry.contentRect.height + 48 : 0;
        }
      });

      resizeObserver.observe(element);
      this.cdr.detectChanges();
    }
  }

  private showMigrationLandingPageOnFirstLogin() {
    // goto migration landing page after first login. Routing needs to be done
    // in setTimeout because router is not initialized completely in ngOnInit of
    // root component
    this.userWatcher = this.store.select(userSelector).subscribe((user) => {
      if (user == null) {
        return;
      }

      if (user.app_metadata.first_login == null || user.app_metadata.first_login.length === 0) {
        this.store.dispatch(
          new UpdateUserAppMetadata({
            key: 'first_login',
            value: new Date().toISOString(),
          })
        );

        setTimeout(() => {
          void this.router.navigate(['migration']);
        });
      }
    });
  }
}
