var x = String;
var create = function () {
  return {
    isColorSupported: false,
    reset: x,
    bold: x,
    dim: x,
    italic: x,
    underline: x,
    inverse: x,
    hidden: x,
    strikethrough: x,
    black: x,
    red: x,
    green: x,
    yellow: x,
    blue: x,
    magenta: x,
    cyan: x,
    white: x,
    gray: x,
    bgBlack: x,
    bgRed: x,
    bgGreen: x,
    bgYellow: x,
    bgBlue: x,
    bgMagenta: x,
    bgCyan: x,
    bgWhite: x,
    blackBright: x,
    redBright: x,
    greenBright: x,
    yellowBright: x,
    blueBright: x,
    magentaBright: x,
    cyanBright: x,
    whiteBright: x,
    bgBlackBright: x,
    bgRedBright: x,
    bgGreenBright: x,
    bgYellowBright: x,
    bgBlueBright: x,
    bgMagentaBright: x,
    bgCyanBright: x,
    bgWhiteBright: x
  };
};
module.exports = create();
module.exports.createColors = create;